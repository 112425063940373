import { configureStore } from "@reduxjs/toolkit";
import hostelReducer from './hostel'
import bookingReducer from "./booking"

const store = configureStore({
    reducer: {
        hostel: hostelReducer,
        booking: bookingReducer
    }
})

export default store