import React from "react";

class Refund extends React.Component {
    render() {
        return (
            <div className="pt-5 p-xl-5 p-3">
                <h1 className="mt-5 mb-3">Refund / Cancellation Policy</h1>
                <div className="h4 my-3">
                    Bookings are not eligible for customer cancellations
                </div>
                In case the property is not able to host you due to any local or subjective circumstances,
                we will refund you the same amount into your bank account.
                You will receive an email & SMS post cancellation and refund with be completed within 5-7 days
                of booking cancellation
            </div>
        )
    }
}

export default Refund