import React from "react";
import {
    Button,
    Card,
    CardBody,
    Input,
    Accordion,
    AccordionItem,
    AccordionBody,
    AccordionHeader,
} from "reactstrap";
import "react-select-search/style.css";
import SelectSearch from "react-select-search";
import { customerFeedback } from "../Config/constants";
import Discover from "../Assets/discover.png";
import Footer from "../Components/Footer";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { baseUrl } from "../index";
import Pool from "../Assets/pool.png";
import Indoor from "../Assets/games.png";
import Jungle from "../Assets/jungle.png";
import Beach from "../Assets/beach.png";
import Mountain from "../Assets/mountain.png";
import Favourite from "../Assets/favourite.png";
import Fire from "../Assets/Fire.png";
import Love from "../Assets/Love.png";
import Money from "../Assets/Money.png";
import "../App.css";
import Marquee from "react-fast-marquee";
import Mandrem from "../Assets/Mandrem.png";
import Arambol from "../Assets/Arambol.png";
import Candolim from "../Assets/Candolim.png";
import Vagator from "../Assets/Vagator.png";
import Ashwem from "../Assets/Ashwem.png";
import Slider from "react-slick";
import { pink, red } from "@mui/material/colors";

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{}} onClick={onClick}>
            <i className="bi bi-chevron-right"></i>
        </div>
    );
};

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{}} onClick={onClick}>
            <i className="bi bi-arrow-right"></i>
        </div>
    );
};

const customerSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    arrows: true,
    indicator: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                initialSlide: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
            },
        },
    ],
};

const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    arrows: true,
    indicator: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                initialSlide: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
            },
        },
    ],
};

class LandingPage extends React.Component {
    constructor() {
        super();
        this.state = {
            searchInput: "",
            fromDate: "",
            toDate: "",
            nextDate: "",
            allHostels: [],
            allLocations: [],
            open: "0",
        };
    }
    componentDidMount() {
        axios
            .get(`${baseUrl}/hostel/all-hostels`)
            .then((response) => {
                this.setState({ allHostels: response.data });
            })
            .catch((err) => {
                console.log(err.message);
            });
        axios
            .get(`${baseUrl}/hostel/hostel-location`)
            .then((response) => {
                this.setState({ allLocations: response.data });
            })
            .catch((err) => {
                console.log(err.message);
            });
        this.setState({ fromDate: new Date(), toDate: new Date() })
    }
    render() {
        const onChange = (e) => {
            const { name, value } = e.target;
            if (name === "fromDate") {
                const nextDay = new Date(value)
                nextDay.setDate(nextDay.getDate() + 1)
                this.setState({ nextDate: nextDay.toISOString().split("T")[0] })
            }
            this.setState({ [name]: value });
        };

        const toggle = (id) => {
            if (this.state.open === id) {
                this.setState({ open: "" });
            } else {
                this.setState({ open: id });
            }
        };

        const onSubmit = () => {
            const { searchInput, fromDate, toDate } = this.state;
            window.location.href = `/search?city=${searchInput}&from=${fromDate}&to=${toDate}`;
        };

        const hostelRedirect = (id) => {
            const today = new Date();
            const tomorrow = new Date(today);
            tomorrow.setDate(today.getDate() + 1);
            window.location.href = `/hostel?hostel=${id}&from=${today.toISOString().split('T')[0]}&to=${tomorrow.toISOString().split('T')[0]}`;
        }

        const searchDisabled = !(
            this.state.searchInput &&
            this.state.toDate &&
            this.state.fromDate
        );

        const handleAmenityClick = (amenity) => {
            const today = new Date().toISOString().split("T")[0];
            const dayaftertomrw = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split("T")[0];
            window.location.href = `/search?amenity=${amenity}&from=${today}&to=${dayaftertomrw}`;
        }

        return (
            <div className="bg-white flex flex-row gap-0">
                <div className="home-background align-content-center">
                    <div className="main-container">
                        <div className="text-white h1 fw-bold text-center mb-3 heading-font">
                            Goa's top hostels curated just for you
                        </div>
                        <div className="row">
                            <div className="d-block d-lg-none">
                                <div
                                    style={{
                                        backgroundColor: "#FFFFFF",
                                        borderRadius: "2rem",
                                    }}
                                    className="p-3"
                                >
                                    <div className="d-flex gap-3 flex-wrap">
                                        <div>
                                            <div className="fw-bold text-dark">
                                                Location
                                            </div>
                                            <SelectSearch
                                                options={
                                                    this.state.allLocations
                                                }
                                                name="searchInput"
                                                search
                                                onChange={(value) => {
                                                    this.setState({
                                                        searchInput: value,
                                                    });
                                                }}
                                                placeholder="Which area do you prefer?"
                                            />
                                        </div>
                                        <div className="ms-auto">
                                            <Button
                                                onClick={onSubmit}
                                                className="book-button"
                                            >
                                                <i className="bi bi-search fs-6"></i>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-10 col-xxl-8 m-auto d-none d-lg-block">
                                <div
                                    style={{
                                        backgroundColor: "#FFFFFF",
                                        borderRadius: "4rem",
                                    }}
                                    className="ps-5 pe-3 py-2"
                                >
                                    <div className="row row-cols-xl-3 row-~cols-1">
                                        <div className="col">
                                            <div className="fw-bold text-dark">
                                                Location
                                            </div>
                                            <SelectSearch
                                                options={
                                                    this.state.allLocations
                                                }
                                                name="searchInput"
                                                search
                                                onChange={(value) => {
                                                    this.setState({
                                                        searchInput: value,
                                                    });
                                                }}
                                                placeholder="Where do you prefer to go?"
                                            />
                                        </div>
                                        <div className="col">
                                            <div className="fw-bold text-dark px-2">
                                                Check In
                                            </div>
                                            <Input
                                                min={
                                                    new Date()
                                                        .toISOString()
                                                        .split("T")[0]
                                                }
                                                className="border-0 custom-date-input"
                                                onChange={onChange}
                                                value={this.state.fromDate}
                                                placeholder="From"
                                                name="fromDate"
                                                type="date"
                                            />
                                        </div>
                                        <div className="col">
                                            <div className="d-flex gap-3 align-items-center flex-wrap">
                                                <div>
                                                    <div className="fw-bold text-dark">
                                                        Check Out
                                                    </div>
                                                    <Input
                                                        min={this.state.nextDate}
                                                        className="border-0 custom-date-input"
                                                        onChange={onChange}
                                                        value={
                                                            this.state.toDate
                                                        }
                                                        placeholder="To"
                                                        name="toDate"
                                                        type="date"
                                                    />
                                                </div>
                                                <div className="ms-auto">
                                                    <Button
                                                        disabled={
                                                            searchDisabled
                                                        }
                                                        onClick={onSubmit}
                                                        className="book-button"
                                                    >
                                                        <i style={{ color: "black"}} className="bi bi-search fs-4"></i>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-container">
                    <div
                        className="text-dark mb-5"
                        style={{ marginTop: "5rem" }}
                    >
                        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-5 g-3 mb-5">
                            <div className="col text-center" onClick={() => handleAmenityClick("Pool")}>
                                <img className="mb-3" src={Pool} alt="pool" />
                                <div className="text-text text-black fw-bold">Pool</div>
                            </div>
                            <div className="col text-center" onClick={() => handleAmenityClick("Indoor games")}>
                                <img
                                    className="mb-3"
                                    src={Indoor}
                                    alt="games"
                                />
                                <div className="text-text text-black fw-bold">Indoor games</div>
                            </div>
                            <div className="col text-center" onClick={() => handleAmenityClick("Jungle")}>
                                <img
                                    className="mb-3"
                                    src={Jungle}
                                    alt="jungle"
                                />
                                <div className="text-text text-black fw-bold">Jungle</div>
                            </div>
                            <div className="col text-center" onClick={() => handleAmenityClick("Beach side")}>
                                <img className="mb-3" src={Beach} alt="beach" />
                                <div className="text-text text-black fw-bold">Beach side</div>
                            </div>
                            <div className="col text-center" onClick={() => handleAmenityClick("Mountain")}>
                                <img
                                    className="mb-3"
                                    src={Mountain}
                                    alt="mountain"
                                />
                                <div className="text-text text-black fw-bold">Mountain</div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center gap-3 justify-content-between mb-3">
                        <div className="text-black h2 fw-bold heading-font mb-4">
                            Properties
                        </div>
                    </div>
                </div>
                <div className="left-container pb-5">
                    <Slider {...settings}>
                        {this.state.allHostels.map((eachHostel) => {
                            return (
                                <div
                                    className="px-3 click"
                                    style={{ minWidth: "300px"}}
                                    key={eachHostel.id} onClick={() => hostelRedirect(eachHostel.id)}
                                >
                                    <img
                                        style={{
                                            width: "100%",
                                            height: "340px",
                                            borderRadius:
                                                "0.75rem",
                                        }}
                                        src={eachHostel.thumbnail}
                                        alt="hostelImage"
                                    />
                                    <div
                                        style={{
                                            borderRadius: "1rem",
                                            backgroundColor: "#000000",
                                            marginTop: "-20rem",
                                            width: "max-content",
                                            opacity: "0.9",
                                        }}
                                        className="mx-2 px-2 py-1 text-white fw-bold"
                                    >
                                        &#8377; {eachHostel.price}
                                    </div>
                                    <div 
                                        style={{
                                            borderRadius:
                                                "0px 0px 0.75rem 0.75rem",
                                            marginTop: "18rem",
                                            boxshadow: "0px 4px 4px 0px #000000FF",
                                            

                                        }}
                                        className="p-3 shadow-md"
                                    >
                                        <div className="text-black fw-bold">
                                            {eachHostel.hostel_name}
                                        </div>
                                        <div className="text-black mb-2">
                                            {eachHostel.location}
                                        </div>
                                        <div className="d-flex gap-2 flex-wrap">
                                            {eachHostel.amenities.map(
                                                (eachAmenity) => {
                                                    return (
                                                        <div
                                                            className="px-2 py-1 rounded text-black"
                                                            style={{
                                                                backgroundColor:
                                                                    "#ffffff",
                                                                    
                                                                fontSize:
                                                                    "0.75rem",
                                                                    boxshadow: "10px 10px 5px 0px #000000CC",

                                                            }}
                                                        >
                                                            {eachAmenity}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
                <div className="bg-pink mt-5">
                    <div className="main-container">
                        <div className="row row-cols-1 row-cols-lg-4 g-5 py-5 pt-0">
                            <div className="col">
                                <Card className="feature-card">
                                    <CardBody>
                                        <img
                                            src={Favourite}
                                            className="mb-2"
                                            alt="HostelCo"
                                        />
                                        <div className="h4 text-dark-o fw-bold mb-2">
                                            Hidden gems
                                        </div>
                                        <div className="text-text">
                                            At the hostel co, you only find the
                                            best. We do the hard work so you
                                            don’t have to.
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col">
                                <Card className="feature-card">
                                    <CardBody>
                                        <img
                                            src={Money}
                                            className="mb-2"
                                            alt="HostelCo"
                                        />
                                        <div className="h4 text-dark-o fw-bold mb-2">
                                            Highest Quality
                                        </div>
                                        <div className="text-text">
                                            At the hostel co, you only find the
                                            best. We do the hard work so you
                                            don’t have to.
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col">
                                <Card className="feature-card">
                                    <CardBody>
                                        <img
                                            src={Fire}
                                            className="mb-2"
                                            alt="HostelCo"
                                        />
                                        <div className="h4 text-dark-o fw-bold mb-2">
                                            Best Amenities
                                        </div>
                                        <div className="text-text">
                                            At the hostel co, you only find the
                                            best. We do the hard work so you
                                            don’t have to.
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col">
                                <Card className="feature-card">
                                    <CardBody>
                                        <img
                                            src={Love}
                                            className="mb-2"
                                            alt="HostelCo"
                                        />
                                        <div className="h4 text-dark-o fw-bold mb-2">
                                            Best People
                                        </div>
                                        <div className="text-text">
                                            At the hostel co, you only find the
                                            best. We do the hard work so you
                                            don’t have to.
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <div className= "bg-tertiary-o">
                <div className="main-container">
                    <div style= {{height: "60vh"}} className="row row-cols-1 row-cols-lg-2 g-3 align-items-center">
                        <div className="col-12 col-lg-3 ">
                            <div className="d-flex align-items-center h-100">
                                <div className="text-white h2 m-4 fw-bold">
                                    What our Customers saying?
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9">
                            <Slider {...customerSettings}>
                                {customerFeedback.map((eachCustomer) => {
                                    return (
                                        <div
                                            className="px-3"
                                            key={eachCustomer.name}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor: "#cecece",
                                                    borderRadius: "1rem",
                                                }}
                                                className="p-3"
                                            >
                                                <div className="d-flex gap-3 align-items-center mb-3">
                                                    <img
                                                        style={{
                                                            width: "40px",
                                                            height: "40px",
                                                            borderRadius: "50%",
                                                        }}
                                                        src={require(`../Assets${eachCustomer.url}`)}
                                                        alt="customer"
                                                    />
                                                    <div>
                                                        <div className="text-black fw-bold">
                                                            {eachCustomer.name}
                                                        </div>
                                                        <div className="text-black mb-2">
                                                            {
                                                                eachCustomer.location
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-blavk mb-2">
                                                    {eachCustomer.decription}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>
                </div>
                <div className="bg-white pb-5">
                    <div className="main-container d-flex gap-3 justify-content-center py-5">
                        <div className="heading-stroke"></div>
                        <div className="heading-font text-red h2 text-center">
                            WHERE WE ARE ?
                        </div>
                        <div className="heading-stroke"></div>
                    </div>
                    <Marquee speed={100} className="mb-5">
                        <div className="d-flex gap-3 align-items-center mx-5">
                            <img src={Ashwem} alt="Ashwem" />
                            <div
                                style={{ fontWeight: "600", fontSize: "3rem" }}
                                className="heading-font text-black"
                            >
                                Ashwem
                            </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center mx-4">
                            <img src={Mandrem} alt="Mandrem" />
                            <div
                                style={{ fontWeight: "600", fontSize: "3rem" }}
                                className="heading-font text-black"
                            >
                                Mandrem
                            </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center mx-4">
                            <img src={Candolim} alt="Candolim" />
                            <div
                                style={{ fontWeight: "600", fontSize: "3rem" }}
                                className="heading-font text-black"
                            >
                                Candolim
                            </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center mx-4">
                            <img src={Arambol} alt="Arambol" />
                            <div
                                style={{ fontWeight: "600", fontSize: "3rem" }}
                                className="heading-font text-black"
                            >
                                Anjuna
                            </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center mx-4">
                            <img src={Vagator} alt="Vagator" />
                            <div
                                style={{ fontWeight: "600", fontSize: "3rem" }}
                                className="heading-font text-black"
                            >
                                Vagator
                            </div>
                        </div>
                    </Marquee>
                    <Marquee speed={100} direction="right" className="mb-3">
                        <div className="d-flex gap-3 align-items-center mx-5">
                            <img src={Ashwem} alt="Ashwem" />
                            <div
                                style={{ fontWeight: "600", fontSize: "3rem" }}
                                className="heading-font text-black"
                            >
                                Ashwem
                            </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center mx-4">
                            <img src={Arambol} alt="Arambol" />
                            <div
                                style={{ fontWeight: "600", fontSize: "3rem" }}
                                className="heading-font text-black"
                            >
                                Anjuna
                            </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center mx-4">
                            <img src={Mandrem} alt="Mandrem" />
                            <div
                                style={{ fontWeight: "600", fontSize: "3rem" }}
                                className="heading-font text-black"
                            >
                                Mandrem
                            </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center mx-4">
                            <img src={Vagator} alt="Vagator" />
                            <div
                                style={{ fontWeight: "600", fontSize: "3rem" }}
                                className="heading-font text-black"
                            >
                                Vagator
                            </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center mx-4">
                            <img src={Candolim} alt="Candolim" />
                            <div
                                style={{ fontWeight: "600", fontSize: "3rem" }}
                                className="heading-font text-black"
                            >
                                Candolim
                            </div>
                        </div>
                    </Marquee>
                </div>
                <div className=" bg-dark-o">
                    <div className="row row-cols-1 row-cols-lg-2 py-5">
                        <div className="col d-flex justify-content-center flex-column">
                            <div className="h2 m-4 text-white heading-font">
                                Calling All Creators:
                            </div>
                            <div className="text-red h2 m-4 heading-font mb-3">
                                Travel’s On the House
                            </div>
                            <div className="text-text m-4 mb-3">
                                Have more than 1000 followers on Insta? Our
                                Creators Travel Free program offers you a free
                                stay at the best hostels in India. We want to
                                collaborate with artists of all types —
                                photographers, videographers, artists,
                                musicians, DJs, bloggers, and so on.
                            </div>
                            <div>
                                <Button className="primary-button m-4 mb-3">
                                    Learn more
                                </Button>
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex justify-content-center">
                                <img
                                    className="hostel-discover-image"
                                    src={Discover}
                                    alt="discover"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    <div className="main-container py-5">
                        <div className="heading-font-2 text-red h2 text-center mb-5">
                            Frequently asked questions
                        </div>
                        <Accordion open={this.state.open} toggle={toggle}>
                            <AccordionItem className="my-3 bg-transparent border-none">
                                <AccordionHeader
                                    targetId="1"
                                    className="accordion-button-none"
                                >
                                    <p className="me-3 text-dark">
                                        I am an introvert travelling solo for
                                        the first time – how do I ensure my
                                        first Hostel Experience is enjoyable and
                                        safe ?
                                    </p>
                                    <i
                                        class="bi bi-plus-circle-fill position-absolute end-0 ms-5 me-3 fs-1 "
                                        style={{ opacity: 0.5}}
                                    ></i>
                                </AccordionHeader>
                                <AccordionBody
                                    className="text-dark"
                                    accordionId="1"
                                >
                                    At TheHostelCo, we have handpicked the
                                    coolest, most welcoming hostels to give you
                                    a safe, fun, and unforgettable experience.
                                    Perfect for solo travelers, our hostels are
                                    packed with awesome activities and social
                                    spaces where you can meet fellow wanderers
                                    and make connections that could last a
                                    lifetime! So, dive into the Hostel & THC
                                    community vibes, join the fun, and always
                                    respect personal space. Keep on Hostelling
                                    and make the most of every adventure!
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="mb-3 bg-transparent border-none">
                                <AccordionHeader
                                    targetId="2"
                                    className="accordion-button-none"
                                >
                                    <p className="me-3 text-dark">
                                        Where is the HostelCo Network located ?
                                    </p>
                                    <i
                                        class="bi bi-plus-circle-fill position-absolute end-0 ms-5 me-3 fs-1 "
                                        style={{ opacity: 0.5 }}
                                    ></i>
                                </AccordionHeader>
                                <AccordionBody
                                    className="text-dark"
                                    accordionId="2"
                                >
                                    TheHostelCo (THC) currently operates a
                                    vibrant network of hostels across key
                                    backpacker destinations in India, including
                                    Goa, Pondicherry, Rishikesh, Dharamshala,
                                    Bir, and more. We are also rapidly expanding
                                    our presence across Southeast Asia and other
                                    regions in India, aiming to offer a unique
                                    and enriching experience for travelers in
                                    even more exciting locations.
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="mb-3 bg-transparent border-none">
                                <AccordionHeader
                                    targetId="3"
                                    className="accordion-button-none"
                                >
                                    <p className="me-3 text-dark">
                                        How do I choose the perfect Hostel for
                                        my first solo trip?
                                    </p>
                                    <i
                                        class="bi bi-plus-circle-fill position-absolute end-0 ms-5 me-3 fs-1 "
                                        style={{ color: red, opacity: 0.5 }}
                                    ></i>
                                </AccordionHeader>
                                <AccordionBody
                                    className="text-dark"
                                    accordionId="3"
                                >
                                    To find the perfect hostel, begin by
                                    selecting your desired destination. If
                                    you're flexible about the location, check
                                    out the "Experience" halos. You can filter
                                    hostels based on activities, amenities, and
                                    customer reviews—and voilà! You’ll discover
                                    the hostels that are the best fit for your
                                    travel style and preferences.
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default LandingPage;
