import React from "react";
import {
    Label,
    Button,
    Card,
    CardBody,
    Input,
    Accordion,
    AccordionItem,
    AccordionBody,
    AccordionHeader,
} from "reactstrap";
import Footer from "../Components/Footer"
import withRouter from "../Components/withRouter"
import axios from "axios";
import { baseUrl } from "../index"
import { citySearch } from "../Config/constants";
import SelectSearch from "react-select-search";
import "../App.css"
import MapComponent from "../Components/SearchMap";
import Favourite from "../Assets/favourite.png"
import Hostel from "../Assets/Hostel.png"
import { amenityCalculator, overallFilter, rateCalculator, roomTypeCalculator } from "../Utils/searchFilters";
import { pink, red } from "@mui/material/colors";

class SearchPage extends React.Component {
    constructor() {
        super()
        this.state = {
            fromDate: "",
            toDate: "",
            searchInput: "",
            amenity:"",
            allHostels: [],
            filteredHostels: [],
            roomTypeFilter: [],
            amenityFilter: [],
            ratingFilter: [],
            searchText: "", // New: To store search_text
            faq: "",   
            priceFilterValue: "",
            amenityFilterValue: "",
            ratingFilterValue: "",
            roomTypeFilterValue: "",
            loading: true
        }
    }

    componentDidMount() {
        const { searchParams } = this.props.router
        const city = searchParams.get("city");
        const amenity = searchParams.get("amenity");
        const fromDate = searchParams.get("from");
        const toDate = searchParams.get("to");
        this.setState({ searchInput: city || "", amenity: amenity || "", fromDate, toDate}, () => {
            const { searchInput, amenity, fromDate, toDate } = this.state
            let apiUrl = `${baseUrl}/inventory/search-results?`;
            if (searchInput) {
                apiUrl += `location=${searchInput}&checkInDate=${fromDate}&checkOutDate=${toDate}`;
            } else if (amenity) {
                apiUrl += `amenity=${amenity}&checkInDate=${fromDate}&checkOutDate=${toDate}`;
            }

            this.setState({ loading: true })

            axios.get(apiUrl).then(response => {
                // Destructure seo_data and hostels
                const roomTypeFilter = roomTypeCalculator(response.data.hostels)
                const amenityFilter = amenityCalculator(response.data.hostels)
                const ratingFilter = rateCalculator(response.data.hostels)

                 // Parse FAQ data
            const faqData = response.data.seo_data.faq;
             // Split FAQ string into question-answer pairs by 'Q:'
             const faqPairs = faqData.split('Q:').filter(pair => pair.trim() !== '');  // Remove empty string due to initial Q:

             const questions = [];
             const answers = [];

             faqPairs.forEach(pair => {
                // Split each pair by 'A:' to separate question and answer
                
                const [question, answer] = pair.split('A:');
                
                if (question && answer) {
                    const cleanedQuestion = question.replace(/;/g, '').trim();
                    const cleanedAnswer = answer.replace(/;/g, '').trim();
                    questions.push(cleanedQuestion);  // Clean up leading/trailing spaces
                    answers.push(cleanedAnswer);      // Clean up leading/trailing spaces
                }
            });
                this.setState({ allHostels: response.data.hostels, filteredHostels: response.data.hostels, roomTypeFilter, amenityFilter, ratingFilter,  searchText: response.data.seo_data.search_text, // Set search_text
                    faqQuestions: questions, faqAnswers: answers,loading: false })
            }).catch(err => {
                console.log(err.message)
            })
        })
    }

    render() {
        const { loading, faqQuestions, faqAnswersdata } = this.state

        const onHostel = (id) => {
            const { fromDate, toDate } = this.state
            window.location.href = `/hostel?hostel=${id}&from=${fromDate}&to=${toDate}`
        }

        const onChange = (e) => {
            const { name, value } = e.target;
            const { allHostels, priceFilterValue, ratingFilterValue, roomTypeFilterValue, amenityFilterValue, searchText, faq } = this.state;
        
            this.setState({ [name]: value }, () => {
                const Filters = {
                    priceFilterValue: name === 'priceFilterValue' ? value : priceFilterValue,
                    ratingFilterValue: name === 'ratingFilterValue' ? value : ratingFilterValue,
                    roomTypeFilterValue: name === 'roomTypeFilterValue' ? value : roomTypeFilterValue,
                    amenityFilterValue: name === 'amenityFilterValue' ? value : amenityFilterValue,
                };
        
                const filteredHostels = overallFilter(allHostels, Filters);
                this.setState({ filteredHostels });
            });
        };

        const toggle = (id) => {
            if (this.state.open === id) {
                this.setState({ open: "" });
            } else {
                this.setState({ open: id });
            }
        };

        
        if (loading) {
            return (
                <div className="loading-frame">
                    <div className="spinner"></div>
                    <span className="loading-text">Loading...</span>
                </div>
            );
        }

        return (
            <div className="bg-white">
                <div className="pt-5 border">
                    <div className="main-container">
                    <div className="text-black fw-bold h2 mb-3">{this.state.filteredHostels.length} {this.state.filteredHostels.length <= 1 ? "hostel" : "hostels"} found in {this.state.searchInput?.charAt(0).toUpperCase() + this.state.searchInput?.slice(1).toLowerCase()}</div>
                    {this.state.allHostels.length === 0 &&
                        <div>
                            No hostel is available
                        </div>}
                    </div>
                    <div className ="bg-green flex items-center justify-center py-4">
                    <div className=" main-container d-flex gap-3 flex-wrap">
                        <Input onChange={onChange} value={this.state.priceFilterValue} name="priceFilterValue" style={{ borderRadius: "2rem", width: "max-content" }} type="select">
                            <option value="">Price</option>
                            <option value="LH">Price - low to high</option>
                            <option value="HL">Price - high to low</option>
                        </Input>
                        <Input onChange={onChange} value={this.state.amenityFilterValue} name="amenityFilterValue" style={{ borderRadius: "2rem", width: "max-content" }} type="select">
                            <option value="">Amenities</option>
                            {this.state.amenityFilter.map(eachAmenity => {
                                return (
                                    <option value={eachAmenity} key={eachAmenity}>{eachAmenity}</option>
                                )
                            })}
                        </Input>
                        <Input onChange={onChange} value={this.state.ratingFilterValue} name="ratingFilterValue" style={{ borderRadius: "2rem", width: "max-content" }} type="select">
                            <option value="">Rating</option>
                            {this.state.ratingFilter.map(eachRating => {
                                return (
                                    <option value={eachRating} key={eachRating}>{eachRating}</option>
                                )
                            })}
                        </Input>
                        <Input onChange={onChange} value={this.state.roomTypeFilterValue} name="roomTypeFilterValue" style={{ borderRadius: "2rem", width: "max-content" }} type="select">
                            <option value="">Room Type</option>
                            {this.state.roomTypeFilter.map(eachRoomType => {
                                return (
                                    <option value={eachRoomType} key={eachRoomType}>{eachRoomType}</option>
                                )
                            })}
                        </Input>
                    </div>
                    </div>
                    <div className=" main-container row row-cols-1 row-cols-lg-2 g-3 mb-3">
                        <div className="col">
                            {this.state.filteredHostels.map(eachCard => {
                                return (
                                    <div key={eachCard} className="col">
                                        <Card onClick={() => onHostel(eachCard.id)} style={{ cursor: "pointer" }} className="feature-card mb-3">
                                            <CardBody>
                                                <div className="d-flex gap-3 flex-wrap">
                                                    <div>
                                                        <img src={eachCard.thumbnail} style={{ borderRadius: "1rem", marginBottom: "4rem", width: "300px" }} alt="HostelCo" />
                                                        <div style={{borderRadius: "1rem", marginTop: "-6.5rem", width: "max-content", zIndex: "4",backgroundColor: "#251313",opacity:'0.9' }} className="bg-tertiary-o px-2 py-1 ms-2 text-white">Rs. {eachCard.price}</div>
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-between">
                                                        <div>
                                                            <div className="text-red fw-bold">{eachCard.hostel_name}</div>
                                                            <div className="text-text mb-2">{eachCard.location}</div>
                                                            <div className="d-flex gap-2 flex-wrap mb-5">
                                                                {eachCard.amenities.map(eachAmenity => {
                                                                    return (
                                                                        <div className="px-2 py-1 rounded text-white" style={{ backgroundColor: "#402929", fontSize: "0.75rem", boxShadow: "0px 4px 4px 0px #00000040", }}>{eachAmenity}</div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="text-white px-3 py-1" style={{ backgroundColor: "#2D7912", borderRadius: "2rem", width: "max-content", boxShadow: "0px 4px 4px 0px #00000040",}}>
                                                            <i className="bi bi-star-fill"></i> {eachCard.rating}
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>
                <div className="bg-dark-o">
                <div className="main-container d-flex gap-3 justify-content-between m-5 flex-wrap">
                <div>
                            <div className=" h4 text-white my-3 fw-bold">About</div>
                            <div className="text-white mb-5">
                            {this.state.searchText}
                            </div>
                        </div>
                </div>
                </div>
                <div className="bg-white">
                    <div className="main-container py-5">
                        <div className="heading-font-2 text-red h2 text-center mb-5">
                        Frequently asked questions
                        </div>
                        <Accordion open={this.state.open} toggle={toggle}>
                        {this.state.faqQuestions && this.state.faqQuestions.length > 0 ? (
                        this.state.faqQuestions.map((question, index) => (
                        <AccordionItem key={index} className="mb-3 bg-transparent border-none">
                        <AccordionHeader
                            targetId={index + 1}
                            className="accordion-button-none"
                        >
                            <p className="me-3 text-dark">{question}</p>
                            <i
                                className="bi bi-plus-circle-fill position-absolute end-0 ms-5 me-3 fs-1"
                                style={{ opacity: 0.5 }}
                            ></i>
                        </AccordionHeader>
                        <AccordionBody
                            className="text-dark"
                            accordionId={index + 1}
                        >
                            {this.state.faqAnswers[index]}
                        </AccordionBody>
                    </AccordionItem>
                ))
            ) : (
                <div>No FAQs available</div>
            )}
        </Accordion>
    </div>
</div>

                <Footer />
            </div>
        )
    }
}

export default withRouter(SearchPage)
