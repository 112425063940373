import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    hostel: {

    }
}

export const hostelSlice = createSlice({
    name: "hostel",
    initialState,
    reducers: {
        searchedHostel: (state, action) => {
            state.hostel = action.payload
        }
    }
})

export const { searchedHostel } = hostelSlice.actions

export default hostelSlice.reducer
