import React from "react"
import Logo from "../Assets/Logo.svg"

class Footer extends React.Component {
    render() {
        return (
            <div className="bg-tertiary-o p-lg-5 p-3">
                <div className="d-flex flex-lg-row flex-column justify-content-between mb-5 gap-3">
                    <div className="col-lg-4 col-12 m-2">
                        <div>
                            <img src={Logo} alt="logo" height={40} />
                        </div>
                        <div className="col-12 col-xl-10 text-text mt-3">
                            TheHostelco aim to create a new Safe and Social Travel Experience -
                            Allowing a new generation of travellers to break the monotony: Go Off-Track, 
                            Meet new people and experience the magic of Travel
                        </div>
                    </div>
                    <div className="col m-2">
                        <div className="row row-cols-md-2 row-cols-1 g-3">
                            <div className="col">
                                <div className="h3 mb-5 heading-font text-white">COMPANY</div>
                                <div className="mb-3">
                                    <a className="text-text text-decoration-none" href="/privacy">Privacy Policy</a>
                                </div>
                                <div className="mb-3">
                                    <a className="text-text text-decoration-none" href="/terms">Terms & Conditions</a>
                                </div>
                                <div className="mb-3">
                                    <a className="text-text text-decoration-none" href="/refund">Refund Policy</a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="h3 text-white mb-5">CONTACT INFO</div>
                                <div className="mb-3 text-text">Email: palash@thehostelco.com</div>
                                <div className="mb-3 text-text">Address: Shapalgo Products LLP 507, Golf Manor Apt Wind Tunnel Rd ,
                                     Murugeshpalaya , Bengaluru - 560017</div>
                                <div className="mb-3 text-text">Phone Number: +91 967 320 3092</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center text-text">
                    CopyRight <i className="bi bi-c-circle"></i> Shapalgo Products LLP
                </div>
            </div>
        )
    }
}

export default Footer
