export const rateCalculator = (allHostels) => {
    return [...new Set(allHostels.map(item => item.rating))]
}

export const amenityCalculator = (allHostels) => {
    return [...new Set(allHostels.flatMap(item => item.amenities))]
}

export const roomTypeCalculator = (allHostels) => {
    return [...new Set(allHostels.map(item => item.roomType))]
}

export const overallFilter = (allHostels, options) => {
    let filteredHostels = [...allHostels];

    if (options.priceFilterValue !== "") {
        filteredHostels.sort((a, b) => {
            return options.priceFilterValue === 'LH' ? a.price - b.price : b.price - a.price;
        });
    }

    if (options.ratingFilterValue !== "") {
        filteredHostels = filteredHostels.filter(hostel => Number(hostel.rating) === Number(options.ratingFilterValue));
    }

    if (options.roomTypeFilterValue !== "") {
        filteredHostels = filteredHostels.filter(hostel => hostel.roomType === options.roomTypeFilterValue);
    }

    if (options.amenityFilterValue !== "") {
        filteredHostels = filteredHostels.filter(hostel => hostel.amenities.includes(options.amenityFilterValue));
    }

    return filteredHostels;
}