import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bookingData: {

    }
}

export const hostelSlice = createSlice({
    name: "booking",
    initialState,
    reducers: {
        bookedHostel: (state, action) => {
            state.bookingData = action.payload
        }
    }
})

export const { bookedHostel } = hostelSlice.actions

export default hostelSlice.reducer
